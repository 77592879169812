// ProductBrand.tsx

import React from 'react';
import { useFilter } from './hooks/FilterContext';
import { BRAND_NAMES, PRODUCT_CATEGORIES } from './types/globals';

// type ProductCategoryProps = {
//     categories: string[];
// };
const ProductCategory: React.FC = () => {
// const ProductCategory: React.FC<ProductCategoryProps> = ({ categories }) => {
  const { selectedCategory, setSelectedCategory, selectedBrands, setSelectedBrands } = useFilter();

  const categories = PRODUCT_CATEGORIES;

  const handleBrandSelect = (brand: string) => {
    setSelectedCategory(prevSelectedBrands =>
      prevSelectedBrands.includes(brand)
        ? prevSelectedBrands.filter(b => b !== brand)
        : [...prevSelectedBrands, brand]
    );
    // If no brands are selected and a category is being selected, select all brands
    if (selectedBrands.length === 0 && !selectedCategory.includes(brand)) {
      setSelectedBrands(BRAND_NAMES);
    }
  };

  return (
    <>
      <div className="flex flex-wrap mb-4">
        {categories.map((category: any) => (
          <div
            key={category}
            className={`flex items-center justify-center mr-1 mb-1 border
                        ${selectedCategory.includes(category) ? 'bg-indigo-600 text-white' : 'bg-white'}
                        min-w-min max-w-xs px-2 py-1 text-xs truncate md:text-sm lg:text-base cursor-pointer`} // Adjust font size for different screen sizes
            onClick={() => handleBrandSelect(category)}
          >
            {category}
          </div>
        ))}
      </div>

    </>
  );
};

export default ProductCategory;
