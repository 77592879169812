import React, { useState } from 'react';
import { FilterBarProps } from './types/ShoeInterface';


const Header: React.FC<FilterBarProps> = ({ isSticky }) => {
  const headerClasses = isSticky ? 'relative bg-white' : 'fixed top-0 z-10';

  return (
     <>

     <header className={`bg-black text-white p-4 w-full ${headerClasses}`}> 

        {/* <nav className={`bg-black text-white p-4 w-full z-50 ${isSticky ? 'fixed top-0' : 'relative'}`}> */}

    {/* <nav className="bg-black text-white p-4 fixed w-full z-50"> */}
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-3xl font-bold">ND Retail</h1>
          {/* here 1  */}
      </div>
    </header>
    {/* // Add a padding-top to body to accommodate the fixed navbar height */}
    <div className="pt-16">
    </div> 
     </>
  );
};

export default Header;