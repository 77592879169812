// services/itemService.ts
import { FetchInventoryResponse } from '../components/hooks/useFetchInventory';
import { BrandCursors } from '../components/types/ShoeInterface';
import api from './api'


export const getItemsByBrand = async (brandCursors: { [brand: string]: string | null }): Promise<FetchInventoryResponse> => {
  try {
    // console.log("brandCursors", brandCursors);

    const queryParams = new URLSearchParams();
    Object.keys(brandCursors).forEach(key => {
      queryParams.append(key, brandCursors[key] === null ? '' : brandCursors[key] as string);
    });

    const queryString = queryParams.toString();
    // console.log("Query Parameters:", queryString);

    // Append query string to the URL
    const url = `/get-items-by-brands?${queryString}`;

    // console.log("Request URL:", url);

    const response = await api.get(url);
    // console.log("Response Data:", response.data);

    // Handle the parsed data
    return response.data; // Assuming response.data has the structure your front-end expects
  } catch (error) {
    console.error('Error fetching items by brand', error);
    throw new Error('Failed to fetch items');
  }
};
// export const getItemsByBrand = async (brandCursors: BrandCursors): Promise<FetchInventoryResponse> => {
//   // export const getItemsByBrand = async (brandCursors: { [brand: string]: string | null }): Promise<FetchInventoryResponse> => {
//   try {
//     const response = await api.post('/get-items-by-brands', { brandCursors });
//     console.log(response.data);
//     return response.data; // The backend returns items and cursors for each brand
//   } catch (error) {
//     console.error('Error fetching items by brand', error);
//     throw new Error('Failed to fetch items');
//   }
// };



// export const getItemsByBrand = async (brand: string) => {
//   try {
//     const response = await api.get(`/get-item-by-brand/${brand}`);
//     console.log(response.data)
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching items by brand', error);
//   }
// };


export const getAllItems = async (page: number, limit: number, cursor: string | null) => {
  try {
    const cursorParam = cursor ? `&cursor=${cursor}` : '';
    const response = await api.get(`/get-items?page=${page}&limit=${limit}${cursorParam}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all items', error);
  }
};


export const getHours = async () => {
  try {
    const response = await api.get(`/hours`);
    return response.data;
  } catch (error) {
    console.error('Error fetching hours', error);
  }
};


