import React from 'react';


type FooterProps = {
  className?: string;
  isVisible: boolean;

};

// const Footer: React.FC = () => {
  const Footer: React.FC<FooterProps> = ({isVisible }) => {

    return (
      // <footer className={`bg-black text-white p-4 ${className}`}>
      <footer className={`fixed bottom-0 w-full bg-black text-white transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'} h-8`}>
      {/* Adjust the height using Tailwind's height utilities, e.g., h-8 for height: 2rem */}
      <div className="container mx-auto text-center flex items-center justify-center h-full">
        {/* Ensure the content is vertically centered in the footer */}
        <p>&copy; 2023 SKYY STUDIO INC. All Rights Reserved.</p>
      </div>
    </footer>
    );
  };

export default Footer;
  