import React, { createContext, useContext, useState, useEffect, ReactNode, useMemo } from 'react';
import { Inventory } from '../types/inventory_interface';
import { getItemsByBrand } from '../../api/itemService';
import { EnhancedBrandInventory } from '../types/ShoeInterface';

interface InventoryContextState {
  inventory: EnhancedBrandInventory;
  setInventory: (value: React.SetStateAction<EnhancedBrandInventory>) => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  // cursors: { [brand: string]: string | null };
  // setCursors: (value: React.SetStateAction<{ [brand: string]: string | null }>) => void;
}

export const InventoryContext = createContext<InventoryContextState | null>(null);

export const InventoryProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [inventory, setInventory] = useState<EnhancedBrandInventory>({});
  // const [cursors, setCursors] = useState<{ [brand: string]: string | null }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const memoizedInventory = useMemo(() => inventory, [inventory]);
  // const memoizedCursors = useMemo(() => cursors, [cursors]);
  return (
    <InventoryContext.Provider value={{ inventory: memoizedInventory, setInventory, isLoading, setIsLoading}}>
      {children}
    </InventoryContext.Provider>
  );
};

// Updated useInventory hook to include new context values
export const useInventory = () => {
  const context = useContext(InventoryContext);
  if (!context) {
    throw new Error('useInventory must be used within an InventoryProvider');
  }
  return context;
};
