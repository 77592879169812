import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './components/Main';
import Header from './components/Header';
import Banner from './components/Banner';
import FilterBar from './components/FilterBar';
import ShoeGrid from './components/ShoeGrid';
import { InventoryProvider } from './components/hooks/useInvenotry';
import { FilterProvider } from './components/hooks/FilterContext';
import Footer from './components/Footer';

function App() {
  const [isSticky, setIsSticky] = useState(false);
  const [showScroll, setShowScroll] = useState(false);

  function debounce(func :any, wait: any) {
    let timeout: any;
    return function executedFunction(...args: any) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  useEffect(() => {
    // const handleScroll = () => {
      const handleScroll = debounce(() => {

      // Safely query for the filter bar element and check if it exists
      const filterBarElement = document.querySelector('.filter-bar') as HTMLElement;
      const headerElement = document.querySelector('.header') as HTMLElement;
  
      if (filterBarElement && headerElement) {
        // Get the position of the filter bar relative to the viewport
        const filterBarPosition = filterBarElement.getBoundingClientRect().top;
        const headerHeight = headerElement.offsetHeight;
  
        // Determine if the FilterBar should become sticky based on its position and the header's height
        setIsSticky(filterBarPosition <= headerHeight);
      }
            // Check if the user has scrolled more than 400px from the top
            const scrolledDownEnough = window.pageYOffset > 200;
            setShowScroll(scrolledDownEnough);

            const atBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 10; // Adding a 10px buffer
            if (atBottom) {
              setShowScroll(false);
            } else if (!scrolledDownEnough) {
              setShowScroll(true);
            }
    }, 100);
  
    // Listen for scroll events
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);

  }, []);


  return (
    // <CategorizedItemsProvider>

    
    <InventoryProvider>
      <FilterProvider>
    <div className="App">
      <Header isSticky={isSticky}/>
      <Banner />
      <Main />
      <FilterBar isSticky={isSticky} />
      <ShoeGrid />
        <div className="pt-10 md:pt-0">
      <Footer isVisible={!showScroll}/>
      </div>
    </div>
    </FilterProvider>
    </InventoryProvider>
    
    
    // </CategorizedItemsProvider>
  );
}

export default App;
