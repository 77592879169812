// ShoeCard.tsx
import React from 'react';
// import { ShoeItem, ShoeSize,  ShoeCardProps } from './types/ShoeInterface'; // Adjust the import path as necessary
import { ShoeItem, ShoeSize } from './types/ShoeInterface'; // Adjust the import path as necessary

// const ShoeCard: React.FC<ShoeCardProps> = ({ shoe, onClick }) => {

const ShoeCard: React.FC<{ shoe: ShoeItem }> = ({ shoe }) => {
  return (
    // <div onClick={() => onClick(shoe)} className="cursor-pointer">

    <div className="flex flex-col items-center bg-white rounded-lg shadow-md overflow-hidden m-0.5">
     {/* added first one opening div */}
      <img className="w-full object-cover object-center" src={shoe.ecom_image_uris[0]} alt={"title"} />
      <div className="p-1 w-full">
        <div className="font-bold text-xs mb-2 line-clamp-2 minHeight-2rem">
          {shoe.item_name}
        </div>
        <div className="text-xs mb-2 line-clamp-2 minHeight-2rem">
          {shoe.item_desc}
        </div>
        <div className="grid grid-cols-6 gap-0 p-0 items-start bg-white">
        {shoe.sizes.map((size: ShoeSize) => {
          const isAvailable = parseInt(size.quantity) > 0 ;
          if (size.size_name === 'Regular') {
            return null; // Or, for minimal display: return <span key="Regular"></span>;
          }
          return (
            <div
              key={size.size_name}
              className={`relative border text-center ${
                isAvailable ? 'bg-white' : 'bg-gray-100'
              }`}
              style={{ padding: '0.25rem', minHeight: '0.25rem', fontSize: '10px'}}
            >
              {size.size_name}
              {!isAvailable && (
                <div
                  className="absolute top-0 left-0 h-full w-full bg-gray-300 opacity-50"
                  style={{
                    background: 'linear-gradient(to top right, transparent 49.5%, #000 49.5%, #000 50.5%, transparent 50.5%)',
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
      </div>
      {/* added last one closing div */}
    </div>
    // </div>
  );
};

export default ShoeCard;
