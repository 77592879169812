// SizeFilter.tsx
import React, { useEffect, useState } from 'react';
import { useFilter } from './hooks/FilterContext';
import { BRAND_NAMES } from './types/globals';





const ProductSize: React.FC = () => {
  const { selectedSizes, setSelectedSizes, selectedBrands, setSelectedBrands } = useFilter();
  // const [lastSelectedSizeType, setLastSelectedSizeType] = useState<'US' | 'Blundstone' | null>(null);
  // const [sizeTypeToUpdate, setSizeTypeToUpdate] = useState<'US' | 'Blundstone' | null>(null);

    // const allSizes = ['S', 'M', 'L', 'XL']; // Replace with your actual sizes
  
    const allSizes = ['5', '5.5', '6', '6.5','7', '7.5', '8', '8.5','9', '9.5', '10', '10.5','11', '11.5', '12', '12.5', '13'];
    const BlundstoneSizes = ['2', '2.5', '3', '3.5','4', '4.5', '5', '5.5','6', '6.5', '7', '7.5','8', '8.5', '9', '9.5', '10'];
    
    // const handleSizeSelect = (size: string, sizeType: 'US' | 'Blundstone') => {
    //   setTimeout(() => {
    //     setSelectedSizes(prevSelectedSizes => {
    //       // Determine if the selected size type is different from the last selection
    //       const isDifferentType = sizeTypeToUpdate !== null && sizeType !== sizeTypeToUpdate;
  
    //       if (isDifferentType) {
    //         setSizeTypeToUpdate(sizeType);
    //         return [size];
    //       } else {
    //         if (!prevSelectedSizes.includes(size)) {
    //           setSizeTypeToUpdate(sizeType);
    //         }
    //         return prevSelectedSizes.includes(size)
    //           ? prevSelectedSizes.filter(s => s !== size)
    //           : [...prevSelectedSizes, size];
    //       }
    //     });
    //   }, 0);
    // };
    const handleSizeSelect = (size: string, type: 'US' | 'BLUNDSTONE') => {
      setSelectedSizes((prevSelectedSizes: any) => {
        const sizeIndex = prevSelectedSizes.findIndex((s:any) => s.size === size && s.type === type);
        
        if (sizeIndex >= 0) {
          // Size is already selected, remove it
          const newSelectedSizes = prevSelectedSizes.filter((_: any, index: any) => index !== sizeIndex);
          // After removing, check if there are any 'BLUNDSTONE' sizes left and adjust selected brands accordingly
          if (type === 'BLUNDSTONE' && !newSelectedSizes.some((s: any) => s.type === 'BLUNDSTONE')) {
            // If after removal, no BLUNDSTONE sizes are left, possibly adjust selected brands
            // This might be setting it back to all brands or something else based on your logic
            setSelectedBrands(BRAND_NAMES); // Adjust this action based on your needs
          }
          return newSelectedSizes;
        } else {
          // Size is not selected, add it with its type
          const newSelectedSizes = [...prevSelectedSizes, { size, type }];
          // If adding a 'BLUNDSTONE' size, set selected brands to 'BLUNDSTONE'
          if (type === 'BLUNDSTONE') {
            setSelectedBrands(['BLUNDSTONE']);
          } else if (selectedBrands.length === 0 && !newSelectedSizes.some(s => s.type === 'US')) {
            setSelectedBrands(BRAND_NAMES);
          }
          return newSelectedSizes;
        }
      });
    };
    // if (selectedBrands.length === 0 && !selectedCategory.includes(brand)) {
    //   setSelectedBrands(BRAND_NAMES);
    // }
    
    const isSizeSelected = (size: string, type: 'US' | 'BLUNDSTONE') => {
      return selectedSizes.some(s => s.size === size && s.type === type);
    };
    // const handleSizeSelect = (size: string) => {
    //   setSelectedSizes(prevSelectedSizes => 
    //     prevSelectedSizes.includes(size) ? 
    //       prevSelectedSizes.filter(s => s !== size) : 
    //       [...prevSelectedSizes, size]
    //   );
    //   // Optionally update selectedBrands here if necessary
    //   if (selectedBrands.length === 0) {
    //     setSelectedBrands(BRAND_NAMES);
    //   }
    // };

    // const isSizeSelected = (size: string) => selectedSizes.includes(size);

    // useEffect(() => {
      // if (sizeTypeToUpdate) {
      //   setLastSelectedSizeType(sizeTypeToUpdate);
      // }

    //   if (selectedSizes.length > 0 && selectedBrands.length === 0) {
    //     setSelectedBrands(BRAND_NAMES);
    //   }
    // }, [sizeTypeToUpdate, selectedSizes]);
  
    return (
      <>
        <h3 className="text-lg font-semibold mb-4">US Size</h3>
        <div className="flex flex-wrap mb-4"> {/* Horizontal layout with wrapping */}
        {allSizes.map((size) => (
          <div
            key={size + "-US"} // Adding "-US" to ensure unique keys
            className={`flex justify-center items-center mr-2 mb-2 border 
                      ${isSizeSelected(size, 'US') ? 'bg-indigo-600 text-white' : 'bg-white'} 
                      h-10 w-10 cursor-pointer`}
            onClick={() => handleSizeSelect(size, 'US')}
          >
            {size}
          </div>
        ))}
        </div>

        <h3 className="text-lg font-semibold mb-4">Blundstone AUS Size</h3>
        <div className="flex flex-wrap mb-4"> {/* Horizontal layout with wrapping */}
          {BlundstoneSizes.map((size) => (
            <div
              key={size + "-Blundstone"} // Adding "-Blundstone" to ensure unique keys
              className={`flex justify-center items-center mr-2 mb-2 border 
                        ${isSizeSelected(size, 'BLUNDSTONE') ? 'bg-indigo-600 text-white' : 'bg-white'} 
                        h-10 w-10 cursor-pointer`}
              onClick={() => handleSizeSelect(size, 'BLUNDSTONE')}
            >
              {size}
            </div>
          ))}
        </div>
        </>
    );
  };
  
  export default ProductSize;