// src/components/Main.tsx
import React from "react";

const Main = () => {
    return (
        <main>
            <div className="filter-bar">
                {/* Implement your filtering options here */}
            </div>
            <div className="products-grid">
                {/* This will be where you display shoes and their sizes */}
            </div>
        </main>
    );
};

export default Main;
