// LoadMoreButton.tsx
import React from 'react';

interface LoadMoreButtonProps {
  isLoading: boolean;
  onLoadMore: () => void;
}

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({ isLoading, onLoadMore }) => {
  return (
    <div className="flex justify-center mt-8 mb-8">
      <button
        onClick={onLoadMore}
        disabled={isLoading}
        className={`text-white font-bold py-2 px-4 rounded ${isLoading ? 'bg-slate-600' : 'bg-zinc-400 hover:bg-slate-900'} transition duration-500 ease-in-out`}
      >
        Load More
      </button>
    </div>
  );
};

export default LoadMoreButton;
