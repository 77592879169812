// ShoeGrid.tsx
import React, { useContext, useEffect, useRef, useState } from 'react';
import ShoeCard from './ShoeCard';
import { useLocation, useNavigate } from 'react-router-dom';

import { useInventory } from './hooks/useInvenotry';
import { getItemsByBrand } from '../api/itemService';
import { ShoeItem } from './types/ShoeInterface'; // Adjust the import path as necessary
import { useFilter } from './hooks/FilterContext';
import { useFetchInventory } from './hooks/useFetchInventory';
import { BRAND_NAMES } from './types/globals';
import LoadMoreButton from './LoadMoreButton';



const ShoeGrid = () => {
  const { inventory, isLoading, setIsLoading } = useInventory();
  const { fetchInventory } = useFetchInventory();
  // const [isLoading, setIsLoading] = useState(false);
  const { selectedBrands, selectedSizes,  selectedGender, selectedCategory } = useFilter(); // Using selectedBrands from FilterContext
  const brandsToDisplay = selectedBrands.length > 0 ? selectedBrands : ["LACOSTE"];
  // const navigate = useNavigate();
  // const location = useLocation();
  // const shoeRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  useEffect(() => {
    setIsLoading(true);
    fetchInventory(selectedBrands, true)
      .finally(() => setIsLoading(false));
  }, [selectedBrands, selectedSizes, selectedGender, selectedCategory]); // Only re-run the effect if selectedBrands changes

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(location.search);
  //   const shoeId = urlParams.get('shoeId');
  //   if (shoeId && shoeRefs.current[shoeId]) {
  //     shoeRefs.current[shoeId]?.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [location.search]);
  
  const handleLoadMore = async () => {
    setIsLoading(true);
    await fetchInventory(selectedBrands, true)
      .finally(() => setIsLoading(false));
  };

  const itemsByBrand = Object.entries(inventory).flatMap(([brand, data]) => {
    const items = selectedBrands.includes(brand) ? data.items : [];
    // console.log(`Items for brand ${brand}:`, items);
    return items;
  });

  const filteredByCategory = itemsByBrand.filter(item => {
    const hasCategory = selectedCategory.length === 0 || selectedCategory.includes(item.category);
    // console.log(`Has category (${selectedCategory.join(', ')}): ${hasCategory}`, item);
    return hasCategory;
  });

  // Now, let's filter by size
  // Function to check if a given item has any of the selected sizes available
  const isSizeAvailable = (item: any) => {
    if (selectedSizes.length === 0) return true; // No size filter applied
    
    return item.sizes.some((itemSize: any) => 
      selectedSizes.some(selectedSize => 
        selectedSize.size === itemSize.size_name && 
        parseInt(itemSize.quantity, 10) > 0 && 
        // Ensure we are checking the size type as well
        (selectedSize.type === 'US' ? itemSize : !itemSize.isUS)
      )
    );
  };

  const filteredBySize = filteredByCategory.filter(isSizeAvailable);

// Finally, let's filter by gender
const displayedItems = filteredBySize.filter(item => {
  const hasGender = selectedGender.length === 0 || item.gender.some(gender => selectedGender.includes(gender));
  // console.log(`Has gender (${selectedGender.join(', ')}): ${hasGender}`, item);
  return hasGender;
});


  // Determine if the "Load More" button should be shown
  const canLoadMore = selectedBrands.some(brand => inventory[brand]?.cursor);
 
  // const handleShoeClick = (shoe: ShoeItem) => {
  //   const shoeLink = `${window.location.origin}${window.location.pathname}?shoeId=${shoe.id}`;
  //   navigate(`?shoeId=${shoe.id}`);
  //   if (shoeRefs.current[shoe.id]) {
  //     shoeRefs.current[shoe.id]?.scrollIntoView({ behavior: 'smooth' });
  //   }
  //   navigator.clipboard.writeText(shoeLink)
  //     .then(() => alert(`Link copied to clipboard: ${shoeLink}`))
  //     .catch(err => console.error('Failed to copy link: ', err));
  // };
  // console.log("inventory", inventory)
  
  
  return (
    <div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-0 p-0 md:p-20">
        {/* {isLoading && <p>Loading...</p>} */}
        {isLoading && (
  <div className="fixed inset-0 z-50 pointer-events-none">
  <div className="flex justify-center items-center h-full">
    <div className="spinner border-t-transparent border-solid border-blue-500 rounded-full w-8 h-8 border-4 animate-spin"></div>
  </div>
</div>
        )}
        {displayedItems.map((shoe, index) => (
        // {/* {brandsToDisplay.flatMap((brand) => inventory[brand]?.items || []).map((shoe, index) => ( */}
  // <ShoeCard key={shoe.id+index} shoe={shoe} onClick={handleShoeClick} />
      <ShoeCard key={shoe.id+index} shoe={shoe} />

))}
      </div>
      {canLoadMore && (
                <LoadMoreButton isLoading={isLoading} onLoadMore={handleLoadMore} />
      )}
    </div>
  );
};

export default ShoeGrid;

