// useFetchInventory.js
import { useContext, useEffect } from 'react';
import { getItemsByBrand } from '../../api/itemService'; // Adjust path as needed
import { InventoryContext } from './useInvenotry'; // Adjust path as needed
import { EnhancedBrandInventory, ShoeItem, BrandCursors } from '../types/ShoeInterface';

export interface FetchInventoryResponse {
    [brand: string]: {
      items: ShoeItem[];
      cursor: string | null; // Updated cursor for next fetch
    };
  }

export const useFetchInventory = () => {
  // const { inventory, setInventory } = useContext(InventoryContext);
  const context = useContext(InventoryContext);

  // Assert that context is not null
  if (!context) {
    throw new Error('useFetchInventory must be used within an InventoryProvider');
  }
  
  const { inventory, setInventory, isLoading, setIsLoading} = context;

  
  // Assert that context is not null
  if (!inventory || !setInventory) {
    throw new Error('useFetchInventory must be used within an InventoryProvider');
  }


  const fetchInventory = async (selectedBrands: string[], lazyLoad: boolean) => {
    // console.log("selectedBrands", selectedBrands)
    // Build an object mapping brands to their current cursor, if any, considering the lazyLoad flag
    // Modified logic for building brandCursors
    const brandCursors: BrandCursors = selectedBrands.reduce((acc, brand) => {
      // Directly access the inventory for the brand
      const brandData = inventory[brand];

      // Check if the brand is not in inventory or if not all items are loaded.
      // Always attempt to fetch if lazyLoad is false (initial load).
      if (!brandData || !brandData.allItemsLoaded || !lazyLoad) {
        acc[brand] = brandData ? brandData.cursor : null;
      }

      return acc;
    }, {} as BrandCursors);

    // If all selected brands have their items loaded, or there are no brands to fetch, skip fetching
    if (Object.keys(brandCursors).length === 0) {
      console.log('No brands need fetching or all items are loaded for selected brands.');
      return;
    }

    // done checkong the localstorages

    setIsLoading(true);
    try {
      // The getItemsByBrand function is expected to take an object with a brandCursors property
      const response = await getItemsByBrand(brandCursors);
      
      setInventory((prevInventory: EnhancedBrandInventory) => {
        const updatedInventory = { ...prevInventory };
        for (const [brand, { items, cursor }] of Object.entries(response)) {
          // Safely combine new items with any existing ones, avoiding duplicates
          const existingItems = prevInventory[brand]?.items ?? [];
          const newItems = items.filter(item => !existingItems.some(eItem => eItem.id === item.id));
          updatedInventory[brand] = {
            items: [...existingItems, ...newItems],
            cursor: cursor,
            allItemsLoaded: !cursor,
          };
        }
        return updatedInventory;
      });
    } catch (error) {
      console.error('Failed to fetch inventory:', error);
    } finally {
      setIsLoading(false);
    }
  };
  return { fetchInventory };
};