import React from 'react';
import ProductGender from './ProductGender';
import ProductSize from './ProductSize';
import ProductBrand from './ProductBrand';
import ProductCategory from './ProductCategory';

// Define the props expected by the FilterSortToggle component
interface FilterSortToggleProps {
  isFilterSortOpen: boolean;
  setIsFilterSortOpen: (isOpen: boolean) => void;
  onClearAll: () => void;
}

const FilterSortToggle: React.FC<FilterSortToggleProps> = ({ isFilterSortOpen, setIsFilterSortOpen, onClearAll }) => {
  return (
    <div className={`fixed inset-0 bg-gray-600 bg-opacity-50 z-50 ${isFilterSortOpen ? 'block' : 'hidden'}`}>
      <aside className="absolute right-0 top-0 w-80 bg-white h-full shadow-xl z-50 p-4 overflow-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Filter & Sort</h2>
          <button onClick={() => setIsFilterSortOpen(false)}>
            <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        
        <button className="font-semibold mb-6" onClick={onClearAll}>
          Clear All
        </button>

        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-4">Brand</h3>
          <ProductBrand />
        </div>

        <hr />

        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-4">Category</h3>
          <ProductCategory />
        </div>
        
        <hr />

        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-4">Gender</h3>
          <ProductGender />
        </div>
        
        <hr />

        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-4">Size:</h3>
          <ProductSize />
        </div>

        <div className="flex justify-end mt-4">
          <button className="bg-black text-white py-2 px-4 flex items-center justify-between w-full" onClick={(e) => {
            e.preventDefault();
            setIsFilterSortOpen(!isFilterSortOpen);
          }}>
            <span className="font-bold">APPLY</span>
            <svg className="ml-2 w-6 h-6" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
            </svg>
          </button>
        </div>
      </aside>

      <button className="fixed inset-0 w-full h-full cursor-default" onClick={() => setIsFilterSortOpen(false)} />
    </div>
  );
};

export default FilterSortToggle;
