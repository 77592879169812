// FilterBar.tsx
import React, { useEffect, useState } from 'react';
import { BRAND_NAMES } from './types/globals'; // Adjust the path as necessary
import { useInventory } from './hooks/useInvenotry';
import { useFilter } from './hooks/FilterContext';
import FilterSortToggle from './FilterSortToggle';
import { FilterBarProps } from './types/ShoeInterface';
// import { useFilter } from './hooks/useFilter';
// import { useFilter } from './hooks/useFilter';

// const filters = ['Sneakers', 'Running', 'Originals', 'Walking', 'Slides & Sandals', 'Soccer', 'Workout & Gym', 'Basketball'];
const filters = BRAND_NAMES;

const FilterBar: React.FC<FilterBarProps> = ({ isSticky }) => {
  // FilterBar should only become 'fixed' when isSticky is true
  // const filterBarClasses = isSticky ? 'fixed top-[headerHeight] w-full z-20' : 'relative';
  const filterBarClasses = isSticky ? 'relative' : 'sticky top-0 z-20';

// const FilterBar = ({isSticky}) => {
  // const [activeFilter, setActiveFilter] = React.useState('LACOSTE');
  // const { fetchInventory } = useInventory();
  // const {selectedBrands, setSelectedBrands} = useFilter();
  // const [activeFilter, setActiveFilter] = useState('Sneakers');
  // const [activeFilter, setActiveFilter] = useState(BRAND_NAMES[0] || ""); // Default to the first brand
  // const [activeFilter, setActiveFilter] = useState(activeBrand || ""); // Default to the first brand

  const {
    isFilterSortOpen,
    setIsFilterSortOpen,
    onClearAll,
    selectedBrands,
    setSelectedBrands,
  } = useFilter();


  // const [activeFilter, setActiveFilter] = useState(selectedBrands || "LACOSTE");
  const [activeFilters, setActiveFilters] = useState<string[]>(selectedBrands || "LACOSTE");

    // Effect to synchronize activeFilters with changes to selectedBrands from outside this component.
  useEffect(() => {
      setActiveFilters(selectedBrands);
    }, [selectedBrands]);

    const handleFilterClick = (filter: string) => {
      setActiveFilters([filter]); // Locally, keep only one active filter.
      setSelectedBrands([filter]); // Update context to reflect this choice.
    };


  return (
    <div className={`filter-bar bg-white p-5 ${filterBarClasses}`}>

    {/* // <div className="bg-white-100 p-4"> */}
      <div className="flex md:justify-center items-center md:space-x-2">

        {/* Scrollable filter container for mobile */}
        {/* <div className="flex overflow-x-auto scrollbar-hide md:hidden text-xs"> */}
        <div className="flex overflow-x-auto scrollbar-hide text-xs md:overflow-x-visible md:space-x-4 md:text-base">
          <div className="flex space-x-4 min-w-max">
            {/* Filter buttons */}
            {filters.map((filter) => (
          <button
            key={filter}
            className={`whitespace-nowrap py-2 ${activeFilters.includes(filter) ? 'border-b-2 border-black' : 'border-b-2 border-transparent'} hover:border-black`}
            onClick={() => handleFilterClick(filter)}
          >
            {filter}
          </button>
            ))}
          </div>
        </div>        

        {/* Filter & Sort button, fixed on the right on mobile */}
        <div className=" flex-shrink-0">
  {/* Button without borders for mobile */}
  <button className="border-0 md:border md:mx-2 px-2 py-1 rounded text-xs flex items-center justify-center bg-transparent md:bg-white"
        onClick={(e) => {
          e.preventDefault();
          setIsFilterSortOpen(!isFilterSortOpen);
        }}    
  >
    {/* SVG icon visible on all screen sizes */}
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
    </svg>
  
    {/* Text visible only on medium screens and larger */}
    <span className="hidden md:inline ml-2 md:text-base">Filter & Sort</span>
  </button>
</div>


      </div>
      <hr />
      
      {isFilterSortOpen && <FilterSortToggle
          isFilterSortOpen={isFilterSortOpen}
          setIsFilterSortOpen={setIsFilterSortOpen}
          onClearAll={onClearAll}
      />}
    </div>
  );
};

export default FilterBar;
