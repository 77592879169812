// ProductBrand.tsx

import React from 'react';
import { useFilter } from './hooks/FilterContext';
import { BRAND_NAMES } from './types/globals';


const ProductGender: React.FC = () => {
  const { selectedGender, setSelectedGender, selectedBrands, setSelectedBrands } = useFilter();

  const GENDERS = ["MALE", "FEMALE"];

  const handleGenderSelect = (gender: string) => {
    setSelectedGender(prevSelectedGender =>
        prevSelectedGender.includes(gender)
        ? prevSelectedGender.filter(g => g !== gender)
        : [...prevSelectedGender, gender]
    );
    if (selectedBrands.length === 0) {
      setSelectedBrands(BRAND_NAMES);
    }
  };


  return (
    <>
        {/* <h3 className="text-lg font-semibold mb-4">Gender</h3> */}
        <div className="flex flex-wrap mb-4"> {/* Horizontal layout with wrapping */}
        {GENDERS.map((gender) => (
          <div
            key={gender}
            className={`flex items-center justify-center mr-1 mb-1 border
                        ${selectedGender.includes(gender) ? 'bg-indigo-600 text-white' : 'bg-white'}
                        min-w-min max-w-xs px-2 py-1 text-xs truncate md:text-sm lg:text-base cursor-pointer`} // Adjust font size for different screen sizes
            onClick={() => handleGenderSelect(gender)}
          >
            {gender}
          </div>
        ))}
        </div>

    </>
  );
};

export default ProductGender;
