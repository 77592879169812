// ProductBrand.tsx

import React from 'react';
import { useFilter } from './hooks/FilterContext';
import { BRAND_NAMES } from './types/globals';

const ProductBrand: React.FC = () => {
// const ProductBrand: React.FC<ProductBrandProps> = ({ brands }) => {
  const { selectedBrands, setSelectedBrands } = useFilter();
  const brands = BRAND_NAMES;
  const handleBrandSelect = (brand: string) => {
    setSelectedBrands(prevSelectedBrands =>
      prevSelectedBrands.includes(brand)
        ? prevSelectedBrands.filter(b => b !== brand)
        : [...prevSelectedBrands, brand]
    );
  };

  return (
    <>
      <div className="flex flex-wrap mb-4">
        {brands.map((brand) => (
          <div
            key={brand}
            className={`flex items-center justify-center mr-1 mb-1 border
                        ${selectedBrands.includes(brand) ? 'bg-indigo-600 text-white' : 'bg-white'}
                        min-w-min max-w-xs px-2 py-1 text-xs truncate md:text-sm lg:text-base cursor-pointer`} // Adjust font size for different screen sizes
            onClick={() => handleBrandSelect(brand)}
          >
            {brand}
          </div>
        ))}
      </div>

    </>
  );
};

export default ProductBrand;
