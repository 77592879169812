import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

import { BRAND_NAMES } from '../types/globals';

export interface SizeSelection {
    size: string;
    type: 'US' | 'Blundstone';
  }
interface FilterContextState {
    selectedBrands: string[];
    setSelectedBrands: React.Dispatch<React.SetStateAction<string[]>>;
    isFilterSortOpen: boolean;
    setIsFilterSortOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClearAll: () => void;
    // selectedSizes: string[];
    selectedSizes: SizeSelection[];
    setSelectedSizes: React.Dispatch<React.SetStateAction<SizeSelection[]>>;
    // setSelectedSizes: React.Dispatch<React.SetStateAction<string[]>>;
    selectedCategory: string[];
    setSelectedCategory: React.Dispatch<React.SetStateAction<string[]>>;
    selectedGender: string[];
    setSelectedGender: React.Dispatch<React.SetStateAction<string[]>>;

}

export const FilterContext = createContext<FilterContextState | undefined>(undefined);

interface FilterProviderProps {
    children: React.ReactNode;
}

export const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
    const [selectedBrands, setSelectedBrands] = useState<string[]>(["LACOSTE"]);
    const [isFilterSortOpen, setIsFilterSortOpen] = useState(false);
    const [selectedSizes, setSelectedSizes] = useState<SizeSelection[]>([]); // Adjusted to use SizeSelection[]
    const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
    const [selectedGender, setSelectedGender] = useState<string[]>([]);


    useEffect(() => {
        // When the filter sort panel closes, check if all filters are empty
        if (!isFilterSortOpen && selectedBrands.length === 0 && selectedSizes.length === 0 && selectedCategory.length === 0 && selectedGender.length === 0) {
          setSelectedBrands(["LACOSTE"]); // Set default filter to "LACOSTE"
        }
        else if (!isFilterSortOpen && selectedBrands.length === 0 && selectedSizes.length > 0) {
            setSelectedBrands(BRAND_NAMES)
        }

        else if (!isFilterSortOpen && selectedBrands.length === 0 && selectedCategory.length > 0) {
            setSelectedBrands(BRAND_NAMES)
        }

      }, [isFilterSortOpen, selectedBrands, selectedSizes, selectedCategory, selectedGender]);
      
    const onClearAll = () => {
        setSelectedBrands([]);
        setSelectedSizes([]);
        setSelectedCategory([]);
        setSelectedGender([]);

        // Add any additional logic to clear filters here.
    };

    const value = {
        selectedBrands,
        setSelectedBrands,
        isFilterSortOpen,
        setIsFilterSortOpen,
        onClearAll,
        selectedSizes,
        setSelectedSizes,
        selectedCategory,
        setSelectedCategory,
        selectedGender,
        setSelectedGender
    };

    // console.log("selectedBrands", selectedBrands)
    // console.log("selectedSizes", selectedSizes)

    return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export const useFilter = () => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error('useFilter must be used within a FilterProvider');
    }
    return context;
};
