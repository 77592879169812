import React, { useState, useEffect } from 'react';
// import { getHours } from '../api/itemService'; // Replace with the correct path to your service file

const Banner: React.FC = () => {
    // const [storeHours, setStoreHours] = useState<string[]>([]);
    const [storeHours] = useState([
        'MONDAY: CLOSED',
        'TUESDAY: 11AM - 6PM',
        'WEDNESDAY: 11AM - 6PM',
        'THURSDAY: 11AM - 6PM',
        'FRIDAY: 11AM - 6PM',
        'SATURDAY: 10AM - 2PM',
        'SUNDAY: CLOSED'
      ]);

    const getCurrentDay = () => {
      const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
      return days[new Date().getDay()];
    };

    const currentDay = getCurrentDay();

    // useEffect(() => {
    //     const fetchHours = async () => {
    //         // const response = await getHours();
    //         const response = "cool"

    //         // if (response && response.weekday_text) {
    //         //     setStoreHours(response.weekday_text);
    //         // }
    //     };

    //     fetchHours();
    // }, []);

    const currentDayStyle = {
      backgroundColor: 'rgba(255, 255, 255, 0.1)', // Slight white transparency over dark background
      borderRadius: '4px', // Optional: adds rounded corners for a softer look
      padding: '2px 4px', // Adds some space around the text
    };
    return (
      <section className="bg-gray-800 text-white text-center py-20">
      <h2 className="text-4xl mb-4">Store Hours</h2>
      <div className="mt-4">
          {storeHours.length > 0 ? (
              storeHours.map((hour, index) => (
                  <p key={index} className={`mb-1 ${hour.startsWith(currentDay) ? 'font-semibold underline' : ''}`}>
                      {hour}
                  </p>
              ))
          ) : (
              <p>Loading Hours...</p>
          )}
      </div>
  </section>
    );
};

export default Banner;