// constants.ts
export const BRAND_NAMES:string[] = [
    "LACOSTE",
    "ASICS", 
    "BLUNDSTONE", 
    "TIMBERLAND", 
    "BAGS", 
    "RIEKER", 
    "REMONTE", 
    "EMU", 
    "MERRELL",
    "ALBERTO", 
    "ADIDAS"
];

export const PRODUCT_CATEGORIES:string[] = [
    "FOOTWEAR",
    "OTHER", 
    // "BAGS", 
];
export {};
